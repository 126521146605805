import React, { useState, useRef, useEffect } from "react";
import Picker from "emoji-picker-react";
import { db, auth } from "../firebase-config";
import {
  doc,
  collection,
  addDoc,
  updateDoc,
  setDoc,
  getDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
} from "firebase/firestore";
// import { useAuth } from "../context/AuthUserContext";
import { Avatar } from "./Avatar";

export const Chat = ({
  chatId,
  newchatId,
  chatType,
  trackDetails,
  openJukeboxPanel,
  userObj,
  chatUsers,
  refreshChatList,
}) => {
  const chatbase = useRef(null);
  const [messages, setMessages] = useState([]);
  const [avatars, setAvatars] = useState({});
  const [newMessage, setNewMessage] = useState("");
  const [inputFocus, setInputFocus] = useState(false);
  const [showPicker, setShowPicker] = useState(false);
  const [trackDetailsObj, setTrackDetailsObj] = useState({});

  const onEmojiClick = (event, emojiObject) => {
    setNewMessage((prevInput) => prevInput + emojiObject.emoji);
    setShowPicker(false);
  };

  useEffect(() => {
    setTrackDetailsObj(trackDetails);
  }, [trackDetails]);

  useEffect(() => {
    chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [messages]);

  useEffect(() => {
    setNewMessage("");
    setMessages([]);
    setAvatars({});
  }, [newchatId]);
  useEffect(() => {
    // console.log("triggered with chatId", chatId);
    if (chatType === "private") {
      // get the full list of uid for this chat first
      // plug that list into the avatar array
      // when the chat is created add all the participants
      // there first and retireve using getDoc(db, "chats", chatId)
      const chatsMessgesRef = collection(db, "chats", chatId, "messages");
      const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
      const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
        let messagesx = [];
        let avatars = [];
        // console.log("chatUsers", chatUsers);
        // chatUsers.forEach((item) => {
        //   avatars.push(item.id);
        //   avatarObj[item.id] = {
        //     displayName: item.displayName,
        //     photoURL: item.photoURL,
        //     email: item.email,
        //   };
        // })

        snapshot.forEach((doc) => {
          // create an avatar list that can be referred to when the chatlist is rendered
          // console.log("doc.data()", doc.data());
          messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
        });

        new Promise(function (resolve, reject) {
          const avatarObj = {};
          const uniq = [...new Set(avatars)];
          // console.log("avatars", avatars);
          // console.log("uniq", uniq);
          console.log('chatUsers', chatUsers);
          let i = 0;
          chatUsers.forEach((item) => {
            i++;
            avatarObj[item.id] = {
              displayName: item.displayName,
              photoURL: item.photoURL,
              email: item.email,
            };
          });
          if (i >= uniq.length) resolve(avatarObj);
          else reject();
        })
          .then(function (avatarObj) {
            // console.log("avatarObj", avatarObj);
            return new Promise((resolve, reject) => {
              setTimeout(() => resolve(setAvatars(avatarObj)), 300);
            });
          })
          .then(function (result) {
            setMessages(messagesx);
            setTimeout(() => {
              updateWatchList(messagesx.length - 1);
              // zero this chatid in the chatlist as it is now being seen and rendered
              // console.log("*@* chatId", chatId);
              // console.log("*@* user", chatUsers[1].id);
              // zeroThisChatlistItem(chatUsers[1].id, chatId);

              // update
            }, 2000);
          });
      });
      // unmark a newchat
      const unmarknewRef = doc(db, "users", auth.currentUser.uid, "chats", chatId);
      updateDoc(unmarknewRef, {newchat: 0});
      refreshChatList()
      return () => unsuscribe();
    } else {
      const chatsMessgesRef = collection(db, "djchats", chatId, "messages");
      const queryMessages = query(chatsMessgesRef, orderBy("createdAt"));
      const unsuscribe = onSnapshot(queryMessages, (snapshot) => {
        let messagesx = [];
        let avatars = [];

        snapshot.forEach((doc) => {
          // create an avatar list that can be referred to when the chatlist is rendered
          // if (!(doc.data().status && doc.data().staus === "closed")) {
          avatars.push(doc.data().uid);
          messagesx.push(Object.assign({ ...doc.data(), id: doc.id }));
          // }
        });

        new Promise(function (resolve, reject) {
          const avatarObj = {};
          const uniq = [...new Set(avatars)];
          let i = 0;
          console.log("chatUsers", chatUsers);
          chatUsers.forEach((item) => {
            i++;
            avatarObj[item.id] = {
              displayName: item.displayName,
              photoURL: item.photoURL,
              email: item.email,
            };
          });
          if (i >= uniq.length) resolve(avatarObj);
          else reject();
          // search for these uids and the photoURL that goes with them
          // uniq.forEach(async (uid) => {
          //   i++;
          //   const docRef = doc(db, "users", uid);
          //   const docSnap = await getDoc(docRef);
          //   console.log('uid', uid)
          //   console.log('docSnap.data()', docSnap.data())
          //   if (docSnap.exists()) {
          //     avatarObj[uid] = {
          //       displayName: docSnap.data().displayName,
          //       photoURL: docSnap.data().photoURL,
          //       email: docSnap.data().email,
          //     };
          //   } else {
          //     // some random image maybe an ai generated icon and displayname
          //     avatarObj[uid] = {
          //       displayName: docSnap.data().displayName || "xxx",
          //       photoURL: docSnap.data().photoURL || "xxx",
          //     };
          //   }
          // });
          // if (i >= uniq.length) resolve(avatarObj);
          // else reject();
        })
          .then(function (avatarObj) {
            return new Promise((resolve, reject) => {
              setTimeout(() => resolve(setAvatars(avatarObj)), 300);
            });
          })
          .then(function (result) {
            setMessages(messagesx);
            // setTimeout(() => {
            //   updateWatchList(messagesx.length - 1);
            //   // update
            // }, 2000);
          });
      });
      return () => unsuscribe();
    }
  }, [chatId]);

  const handleClose = async (event) => {
    event.preventDefault();
    const deleteRequestRef = doc(db, "djchats", chatId);
    updateDoc(deleteRequestRef, {
      status: "closed",
    });
    //open jukebox
    openJukeboxPanel(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setInputFocus(false);
    chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    if (chatType === "djchat") return;
    if (newMessage === "") return;
    // check if auth.currentUser is available. find a way to wait and check again if it is not before proceeding witht he submit.
    // this is necessarry due to poor internet connections
    // console.log("auth.currentUser", auth.currentUser);
    // console.log("chatUsers", chatUsers);
    const messagesRef4 = collection(db, "chats", chatId, "messages");
    await addDoc(messagesRef4, {
      text: newMessage,
      createdAt: serverTimestamp(),
      user: chatUsers[0].displayName, //auth.currentUser.displayName || auth.currentUser.email,
      uid: chatUsers[0].id, //auth.currentUser.uid,
    });

    setTimeout(async () => {
      updateWatchList(messages.length);

      //now go look for all the other chat users personal chat listand update the updatedAt field
      // so it can be snapped on in ChatList

      const otherUsersRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "chats",
        chatId
      );
      const otherUsersDoc = await getDoc(otherUsersRef);
      if (otherUsersDoc.exists) {
        const otherUserList = otherUsersDoc.data().users;
        otherUserList.forEach(async (item, i) => {
          try {
            const updatedAt = {
              chatLength: messages.length,
            };
            const otherItemRef = doc(db, "users", item.id, "chats", chatId);
            const otherItem = await getDoc(otherItemRef);
            if (otherItem.exists()) {
              await updateDoc(otherItemRef, { ...updatedAt });
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    }, 500);

    // also update the user chat so the other user can be informed there are new messages in this chat
    // so, get user list from this users chat then that matches this chatId
    const docRef = doc(db, "users", auth.currentUser.uid);
    const docSnap = await getDoc(docRef);
    const currentChat = { currentChat: chatId };
    if (docSnap.exists()) {
      try {
        await updateDoc(docRef, { ...currentChat });
      } catch (err) {
        console.log(err);
      }
    }
    setNewMessage("");
    setTimeout(() => {
      chatbase.current.scrollIntoView({ behavior: "smooth", block: "end" });
    }, 500);
  };

  const updateWatchList = async (msgsLength) => {
    const updatewatchListRef2 = doc(
      db,
      "users",
      auth.currentUser.uid,
      "updatewatchList",
      chatId
    );

    try {
      const thisChat2 = {
        chatId: chatId,
        updatedAt: serverTimestamp(),
        chatLength: msgsLength,
      };
      const updatewatchListDocSnap = await getDoc(updatewatchListRef2);
      if (updatewatchListDocSnap.exists()) {
        await setDoc(
          doc(db, "users", auth.currentUser.uid, "updatewatchList", chatId),
          thisChat2
        );
      } else {
        await setDoc(
          doc(db, "users", auth.currentUser.uid, "updatewatchList", chatId),
          thisChat2
        );
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const zeroThisChatlistItem = async (userId, chatId) => {
  //   const updateChatListRef = doc(
  //     db,
  //     "users",
  //     userId, //auth.currentUser.uid,
  //     "chats",
  //     chatId
  //   );
  //   const unreadAmount = { unreadAmount: 0 };
  //   try {
  //     await updateDoc(updateChatListRef, { ...unreadAmount });
  //   } catch (err) {
  //     console.log("update Amount for chat:", err);
  //   }
  // };
  let lastID = 0;
  const TrackDetails = () => {
    if (trackDetailsObj) {
      return (
        <div className="flex items-center py-4 px-3" style={{ width: "81%" }}>
          <img
            className="rounded-full object-cover mr-1"
            src={trackDetailsObj.coverart}
            style={{ width: "48px" }}
            alt="cove art"
          />
          <div className="flex-1" style={{ width: "50%" }}>
            <h3 className="text-lg font-medium text-white overflow-hidden">
              {trackDetailsObj.artist}
            </h3>
            <p className="text-gray-100 text-base">{trackDetailsObj.track} </p>
          </div>
        </div>
      );
    } else {
      return <div>song: Removed</div>;
    }
  };

  const UserName = () => {
    // mark this chat as read in the ChatList
    markDJChatAsRead();
    return (
      <div className="text-white text-lg">
        <strong>{userObj.displayName}</strong> requested
      </div>
    );
  };

  const ChatUser = ({ chatId }) => {
    // const index = messages.findIndex((message) => {
    //   return !(
    //     auth.currentUser.displayName === message.user ||
    //     auth.currentUser.email === message.user
    //   )
    //     ? message.uid
    //     : null;
    // });
    // const uid = messages[index]?.uid || null;

    // const index = avatars.findIndex((item, i) => {
    //   return !(
    //     auth.currentUser.displayName === item.user ||
    //     auth.currentUser.email === item.user
    //   )
    //     ? i
    //     : null;
    // });

    let uid;
    for (const key in avatars) {
      if (avatars.hasOwnProperty(key)) {
        // console.log(`${key}: ${avatars[key]}`);
        // console.log("auth.currentUser.uid", auth.currentUser.uid);
        if (key !== auth.currentUser.uid) uid = key;
      }
    }

    // console.log("uid", uid);
    // console.log("avatars", avatars);
    return (
      <div className="flex flex-row items-center">
        {uid && (
          <div className="flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden ebzbg-indigo-500 flex-shrink-0 z-10 mr-3">
            <Avatar avObj={avatars} uid={uid} />
          </div>
        )}
        <div className="text-base">{avatars[uid]?.displayName}</div>
        <button
          className="hide-chat"
          onClick={() => {
            addChatToHiddenList(chatId);
          }}
        >
          <svg
            fill="#ffffff"
            width="20px"
            height="20px"
            viewBox="0 0 48 48"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 0h48v48H0z" fill="none" />
            <g id="Shopicon">
              <path
                d="M11.957,33.215L7.172,38L10,40.828l5.305-5.305C17.867,36.992,20.788,38,24,38c12,0,20-14,20-14s-2.954-5.16-7.957-9.215
		L40.828,10L38,7.172l-5.305,5.305C30.133,11.008,27.212,10,24,10C12,10,4,24,4,24S6.954,29.16,11.957,33.215z M33.204,17.624
		c2.668,2.091,4.747,4.638,5.996,6.369C36.728,27.396,31.024,34,24,34c-2.048,0-3.973-0.563-5.742-1.43l1.684-1.684
		C21.133,31.589,22.517,32,24,32c4.418,0,8-3.582,8-8c0-1.483-0.411-2.867-1.114-4.058L33.204,17.624z M20.149,25.023
		C20.062,24.694,20,24.356,20,24c0-2.206,1.794-4,4-4c0.356,0,0.694,0.062,1.023,0.149L20.149,25.023z M27.851,22.977
		C27.938,23.306,28,23.644,28,24c0,2.206-1.794,4-4,4c-0.356,0-0.694-0.062-1.023-0.149L27.851,22.977z M24,14
		c2.048,0,3.973,0.563,5.742,1.43l-1.684,1.684C26.867,16.411,25.483,16,24,16c-4.418,0-8,3.582-8,8
		c0,1.483,0.411,2.867,1.114,4.058l-2.318,2.318c-2.668-2.091-4.747-4.638-5.997-6.369C11.272,20.604,16.976,14,24,14z"
              />
            </g>
          </svg>
        </button>
      </div>
    );
  };

  const addChatToHiddenList = (chatId) => {
    const hideRequestRef = doc(
      db,
      "users",
      auth.currentUser.uid,
      "chats",
      chatId
    );
    updateDoc(hideRequestRef, {
      status: "hidden",
    });
    //open jukebox
    //openJukeboxPanel(true);
  };

  const markDJChatAsRead = () => {
    const readDJResponseRef = doc(db, "djchats", chatId);
    updateDoc(readDJResponseRef, {
      unreadAmount: 0,
    });
  };

  return (
    <>
      <div
        className="flex flex-col flex-auto p-6 -z-0"
        style={{ height: "calc(100dvh - 162px)" }}
      >
        <div className="flex flex-col flex-auto flex-shrink-0 rounded-2xl bg-gray-100 dark:bg-gray-900 h-full p-4 panel-fade">
          {chatType === "private" && (
            <div className="flex flex-col text-white mb-3 chatuser">
              <ChatUser chatId={chatId} />
            </div>
          )}

          <div
            className="flex flex-col overflow-x-auto mb-4 "
            style={{ height: "calc(100dvh - 182px)" }}
          >
            <div className={`flex flex-col h-full`}>
              <div className={`grid grid-cols-12 gap-y-2`} ref={chatbase}>
                {chatType === "djchat" && (
                  <div className="col-start-1 col-end-12">
                    <UserName />
                    <div className="flex flex-row items-center jukebox-divider">
                      <TrackDetails />
                      <div
                        id="close-request-trigger"
                        class="btn btn-l  mb-1 text-sm leading-4 close-request-btn"
                        onClick={(event) => handleClose(event)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="#ffffff"
                          width="30px"
                          height="30px"
                          viewBox="0 0 24 24"
                        >
                          <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zm2.46-7.12l1.41-1.41L12 12.59l2.12-2.12 1.41 1.41L13.41 14l2.12 2.12-1.41 1.41L12 15.41l-2.12 2.12-1.41-1.41L10.59 14l-2.13-2.12zM15.5 4l-1-1h-5l-1 1H5v2h14V4z" />
                        </svg>
                      </div>
                    </div>

                    <h1 className="text-white mt-1 mb-1">RESPONSE FROM DJ OBAH</h1>
                    <hr />
                  </div>
                )}
                <div></div>
                {messages.map((message, index) => {
                  let noshow = true;
                  if (message.uid !== lastID) {
                    noshow = false;
                    lastID = message.uid;
                  }
                  return (
                    <div
                      className={
                        chatUsers[0].displayName === message.user
                          ? "col-start-2 col-end-13 p-3 rounded-lg chatbubble"
                          : "col-start-1 col-end-12 p-3 rounded-lg chatbubble"
                      }
                      key={index}
                    >
                      <div
                        className={
                          chatUsers[0].displayName === message.user
                            ? "flex items-center justify-start flex-row-reverse"
                            : "flex flex-row items-center"
                        }
                      >
                        {!noshow && (
                          <div
                            className={
                              chatUsers[0].displayName === message.user
                                ? "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden ebzbg-indigo-500 flex-shrink-0  z-10"
                                : "flex items-center justify-center h-14 w-14 rounded-full border overflow-hidden ebzbg-indigo-500 flex-shrink-0  z-10"
                            }
                          >
                            <Avatar avObj={avatars} uid={message.uid} />
                          </div>
                        )}
                        <div
                          className={
                            chatUsers[0].displayName === message.user
                              ? "relative mr-3 text-sm text-gray-800 dark:text-gray-800 bg-indigo-100 py-2 px-4 shadow rounded-l-xl rounded-br-xl z-10"
                              : "relative ml-3 text-sm text-gray-800 dark:text-gray-800 bg-white py-2 px-4 shadow rounded-e-xl rounded-es-xl  z-10"
                          }
                        >
                          <div className="text-left">
                            {/* {index} : {message.user} : */} {message.text}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          {chatType === "private" ? (
            <>
              <div className="w-full">
                {showPicker && (
                  <Picker
                    pickerStyle={{ width: "100%" }}
                    onEmojiClick={onEmojiClick}
                  />
                )}
              </div>
              <div
                className={`flex flex-row items-center h-16 rounded-xl bg-white w-full px-4 h-24 text-enter-fit base-return ${
                  inputFocus === true ? "text-enter-full" : ""
                }`}
              >
                <div>
                  <button
                    className="flex items-center justify-center text-gray-400 hover:text-gray-600"
                    onClick={() => setShowPicker((val) => !val)}
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15.172 7l-6.586 6.586a2 2 0 102.828 2.828l6.414-6.586a4 4 0 00-5.656-5.656l-6.415 6.585a6 6 0 108.486 8.486L20.5 13"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div className="flex-grow ml-2 h-20">
                  <div className="relative w-full">
                    <input
                      type="text"
                      value={newMessage}
                      onFocus={() => setInputFocus(true)}
                      onChange={(event) => setNewMessage(event.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleSubmit(e);
                      }}
                      className="flex w-full text-gray-800 dark:text-gray-800 border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-20"
                    />
                    <button className="absolute flex items-center justify-center h-full w-12 right-0 top-0 text-gray-400 hover:text-gray-600">
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="ml-2">
                  <button
                    onClick={(event) => handleSubmit(event)}
                    className="flex items-center justify-center ebzbg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0 w-16 h-16"
                  >
                    <span className="ml-0">
                      <svg
                        className="w-4 h-4 transform rotate-45 -mt-px"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                        ></path>
                      </svg>
                    </span>
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-row items-center h-16 rounded-xl  w-full px-4 h-24 text-enter-fit text-white">
                <button
                  onClick={() => {
                    openJukeboxPanel()
                  }}
                  className="block w-full text-center px-4 py-2 rounded-xl hover:bg-gray-600 dark:hover:bg-gray-600 dark:hover:text-white muuzmenuitem"
                >
                  <div className="back-btn text-black rounded-sm p-2">
                    <strong>BACK to the music listings</strong>
                  </div>
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};
